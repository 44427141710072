import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { Icon } from "react-icons-kit"
import { envelope } from "react-icons-kit/fa/envelope"
import { phone } from "react-icons-kit/fa/phone"
import { facebookSquare } from "react-icons-kit/fa/facebookSquare"
import { home } from "react-icons-kit/fa/home"
import { instagram } from "react-icons-kit/fa/instagram"

import MainSection from "../components/MainSection"
import ContactPageForm from "../components/ContactForms/ContactPageForm"

export const query = graphql`
  query {
    imagesJson(pageName: { eq: "contact" }) {
      background {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

const ContactPage = ({ data }) => {
  const images = data.imagesJson

  return (
    <Layout>
      <SEO title="Contact" />
      <MainSection image={images.background.childImageSharp.fluid}>
        <p className="lead">Bob Keach &amp; Sons Carpentry</p>
        <h1>Contact</h1>
        <h2>Offering high-quality remodeling service since 1984.</h2>
      </MainSection>

      <section id="contact-page">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <ContactPageForm />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="contact-page-info">
        <Container>
          <Row>
            <Col sm={{ offset: 1 }}>
              <ul className="list-unstyled ml-2 ml-sm-5">
                <li className="h3">Bob Keach &amp; Sons Carpentry</li>
                <li>
                  <Icon size={20} icon={home} />
                  <span>
                    86 Chestnut St Easton, <br />
                    Massachusetts 02356 AS
                  </span>
                </li>
                <li>
                  <Icon size={20} icon={phone} />
                  <a className="text-normal" href="tel:1-508-230-9528">
                    <span>(508) 230-9528</span>
                  </a>
                </li>
                <li>
                  <Icon size={20} icon={phone} />
                  <a className="text-normal" href="tel:1-508-942-0587">
                    <span>(508) 942-0587</span>
                  </a>
                </li>
                <li>
                  <Icon size={20} icon={envelope} />
                  <a
                    className="text-normal"
                    href="mailto:keachcarpentry@yahoo.com"
                  >
                    <span>keachcarpentry@yahoo.com</span>
                  </a>
                </li>

                <li className="social-icons">
                  <a
                    className="facebook-icon"
                    href="https://www.facebook.com/bobkeachandsonscarpenty/"
                  >
                    <Icon size={20} icon={facebookSquare} />
                  </a>
                  <a
                    className="instagram-icon"
                    href="https://www.instagram.com/bobkeachandsonscarpentry/"
                  >
                    <Icon size={20} icon={instagram} />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default ContactPage
