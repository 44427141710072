import React from "react"

import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import SectionTitle from "../LayoutComp/SectionTitle"

import "./ContactPage.css"

import useFormValidation from "./validation/useFormValidation"
import validateAuth from "./validation/validateAuth"

const INITIAL_STATE = {
  email: "",
  name: "",
  message: "",
  phone: "",
}

function ContactPageForm() {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting,
    isSubmitted,
  } = useFormValidation(INITIAL_STATE, validateAuth)

  if (!isSubmitted) {
    return (
      <Form onSubmit={handleSubmit} className="shadow-sm">
        <Form.Row>
          <SectionTitle>Leave us a message</SectionTitle>
          <Form.Group as={Col} md={6}>
            <Form.Control
              onChange={handleChange}
              name="name"
              value={values.name}
              className={errors.name && "error-input"}
              type="text"
              placeholder="Full name"
              aria-label="Your full name"
              aria-required="true"
            />

            <Form.Control
              onChange={handleChange}
              name="email"
              value={values.email}
              className={errors.email && "error-input"}
              type="email"
              placeholder="Enter email"
              aria-label="Your email"
              aria-required="true"
            />

            <Form.Control
              onChange={handleChange}
              name="phone"
              type="text"
              value={values.phone}
              className={errors.phone && "error-input"}
              placeholder="Phone number"
              aria-label="Your phone number"
              aria-required="true"
            />
          </Form.Group>

          <Form.Group as={Col} md={6}>
            <Form.Control
              onChange={handleChange}
              name="message"
              value={values.message}
              className={`align-self-end ${errors.message && "error-input"}`}
              as="textarea"
              rows="4"
              placeholder="Message"
              aria-label="Message"
              aria-required="true"
            />
          </Form.Group>
          <Form.Group as={Col} sm={12} className="text-right mb-0 mt-2">
            <Button
              variant="primary"
              disabled={isSubmitting}
              className="btn-mod small"
              type="submit"
            >
              Send a Message
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    )
  } else {
    return (
      <Form className="shadow-sm">
        <Form.Row>
          <Col xs={12} className="text-center pb-2">
            <p className="h4 pb-2">Thank you for contacting us.</p>
            <p>
              We will look over your message and get back to you within 24h.
            </p>
          </Col>
        </Form.Row>
      </Form>
    )
  }
}

export default ContactPageForm
